import { Grid } from "@mui/material";
import CustomField from "../../forms/CustomField";

interface IProps {
	id: string;
	text: string;
	handleChange: any;
}

export default function TextItem({ id, text, handleChange }: IProps) {
	return (
		<Grid item md={5} xs={12} style={{ margin: "10px" }}>
			<CustomField
				label={id.toUpperCase()}
				name={id}
				value={text}
				multiline
				onChange={(e: any) => handleChange(e.target.value)}
			/>
		</Grid>
	);
}
