import { Grid } from "@mui/material";
import { ITweet } from "../../types/CommonTypes";
import { Tweet } from "react-twitter-widgets";

const TweetCard = ({ id }: ITweet) => {
	return (
		<Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
			<div
				style={{
					display: "block",
					width: "90%",
					justifyContent: "center",
				}}
			>
				<Tweet tweetId={id} options={{ lang: "es" }} />
			</div>
		</Grid>
	);
};

export default TweetCard;
