import { secondary } from "../../commons/constants/colors";

interface TitleProps {
	children: any;
}

export default function Title(props: TitleProps) {
	const { children } = props;
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<div
				style={{
					width: "50%",
					height: "1px",
					backgroundColor: secondary,
				}}
			/>
			<h2
				style={{
					textAlign: "center",
					margin: "0 10px",
					color: secondary,
					textTransform: "uppercase",
				}}
			>
				{children}
			</h2>
			<div
				style={{
					width: "50%",
					height: "1px",
					backgroundColor: secondary,
				}}
			/>
		</div>
	);
}
