import { IItem } from "../../types/CommonTypes";
import { v4 as uuidv4 } from "uuid";

export const getImageById = (id: string, item: IItem) => {
	return item.images.find((image) => image.id === id)?.url;
};

export const getTextById = (id: string, item: IItem) => {
	return item.texts.find((text) => text.id === id)?.text;
};

export function formatDate(initialDate: string): string {
	console.log("REceived", initialDate);
	const format: string = "DD-MM-YYYY - HH:MM";

	const date: Date = new Date(initialDate);

	const day: number = date.getDate();
	const month: number = date.getMonth() + 1;
	const year: number = date.getFullYear();
	const hours: number = date.getHours();
	const minutes: number = date.getMinutes();

	const formattedDate: string = format
		.replace("DD", day < 10 ? "0" + day : day.toString())
		.replace("MM", month < 10 ? "0" + month : month.toString())
		.replace("YYYY", year.toString())
		.replace("HH", hours < 10 ? "0" + hours : hours.toString())
		.replace("MM", minutes < 10 ? "0" + minutes : minutes.toString());

	console.log("Returned", formattedDate);
	return formattedDate;
}

export function getRandomId() {
	return uuidv4();
}

export function getIdByUrl(url: string) {
	// Expresión regular para extraer el ID del video
	var regex =
		/(?:\?v=|\/embed\/|\/\d+\/|\/embed\/|youtu.be\/|\/v\/|\/e\/|\/u\/\w+\/|\/embed\/|\/v\/|\/watch\?v=|&v=)([^#\&\?\/\s]{11})/;

	// Intenta hacer coincidir la expresión regular con la URL proporcionada
	var coincidencia = url.match(regex);

	// Si se encontró una coincidencia, devuelve el ID del video
	if (coincidencia && coincidencia[1]) {
		return coincidencia[1];
	} else {
		// Si no se encontró una coincidencia, devuelve un valor nulo o puede manejar el error de otra manera
		return null;
	}
}

const PAGINATION_ROWS_PER_PAGE = [10, 20, 30, 40, 50];
const PAGINATION_TRANSLATE = {
	rowsPerPageText: "Filas por página:",
	rangeSeparatorText: "de",
	noRowsPerPage: false,
	selectAllRowsItem: false,
	selectAllRowsItemText: "All",
};

//CAROUSEL CONFIG
const CAROUSEL_CONFIG = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 5000,
	dotsClass: "slick-dots",
};

export { PAGINATION_ROWS_PER_PAGE, PAGINATION_TRANSLATE, CAROUSEL_CONFIG };
