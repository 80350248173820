import { useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getRandomId } from "../constants/helpers";
import { saveAs } from "file-saver";
import { IFile } from "../../types/CommonTypes";
// import JSZip from "jszip";

export default function useStorage() {
	const [uploading, setUploading] = useState<boolean>(false);
	const [downloading, setDownloading] = useState<boolean>(false);

	const uploadFile = async (file: any, path: string, username: string) => {
		const storage = getStorage();
		let fileName = getRandomId();
		let fullPath = "/reports/" + path;
		let extensionFile = file.type.split("/")[1];
		const fileRef = ref(
			storage,
			fullPath + "/" + fileName + "." + extensionFile
		);
		setUploading(true);
		try {
			await uploadBytes(fileRef, file);
			const url = await getDownloadURL(fileRef);

			const fileData: IFile = {
				name: fileName,
				type: file.type,
				path: fileRef.fullPath,
				url: url,
				username: username,
			};

			return fileData;
		} catch (error) {
			console.error("Error subiendo archivo: ", error);
			return null;
		} finally {
			setUploading(false);
		}
	};

	const downloadFile = async (file: IFile) => {
		try {
			setDownloading(true);
			// Hacer la solicitud para obtener el archivo
			const respuesta = await fetch(file.url);
			const blob = await respuesta.blob(); // Obtén el archivo como un blob

			saveAs(blob, `${file.username ?? file.name}`);
			setDownloading(false);
		} catch (error) {
			console.error("Error descargando el archivo:", error);
		}
	};

	return {
		uploadFile,
		downloadFile,
		uploading,
		downloading,
	};
}
