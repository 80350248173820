import { Grid } from "@mui/material";
import { IProps } from "../../types/CommonTypes";
import Title from "../generic/Title";
import Item from "./generic/ImageItem";
import { useEffect, useState } from "react";
import useFirebase from "../../commons/firebase/useFirebase";
import TextItem from "./generic/TextItem";
import { getRandomId } from "../../commons/constants/helpers";

export default function DashboardBanner({ item, onChange }: IProps) {
	const [banner, setBanner] = useState(item);
	const { uploadFileToStorage } = useFirebase();

	useEffect(() => {
		onChange && onChange(banner);
	}, [banner]);

	const handleNewPic = async (id: string, file: any) => {
		try {
			let path = "/assets/banner/";
			const downloadURL = await uploadFileToStorage(
				file,
				path + file.name
			);
			let updated: any = banner.images.map((item) => {
				if (item.id === id) {
					return {
						id: id,
						name: getRandomId(),
						path: path,
						url: downloadURL,
					};
				}
				return item;
			});

			setBanner({ ...banner, images: updated });
		} catch (error) {
			console.log("Error al cargar el archivo:", error);
		}
	};

	const handleTextChange = (id: string, value: string) => {
		let updated: any = banner.texts.map((item) => {
			if (item.id === id) {
				return {
					...item,
					text: value,
				};
			}
			return item;
		});

		setBanner({ ...banner, texts: updated });
	};

	return (
		<Grid container>
			<Title>Imágenes</Title>
			{banner.images.map((image, index) => (
				<Item
					key={index}
					label={image.name}
					source={image.url}
					onUpdate={(file: any) => handleNewPic(image.id, file)}
				/>
			))}
			<Title>Textos</Title>
			{banner.texts.map((item, index) => (
				<TextItem
					key={index}
					id={item.id}
					text={item.text}
					handleChange={(value: string) =>
						handleTextChange(item.id, value)
					}
				/>
			))}
		</Grid>
	);
}
