import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import LoginScreen from "../../screens/Login";
import Home from "../../screens/Home";
import Dashboard from "../../screens/Dashboard";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmbedSurvey from "../../screens/Survey";

const App = () => {
	const ProtectedRoute = ({ element }: any) => {
		const auth = getAuth();
		const [user, setUser] = useState(null);
		const [isPending, setPending] = useState(true);
		const location = useLocation();

		useEffect(() => {
			const unsubscribe = onAuthStateChanged(auth, (user: any) => {
				setUser(user);
				setPending(false);
			});

			return unsubscribe;
		}, [auth]);

		if (isPending) {
			return (
				<Box sx={{ display: "flex" }}>
					<CircularProgress />
				</Box>
			); // o un componente de "Cargando..."
		}

		if (user) {
			return element;
		}

		return <Navigate to="/login" state={{ from: location }} />;
	};

	const LoginRoute = ({ element }: any) => {
		const auth = getAuth();
		const [user, setUser] = useState(null);

		useEffect(() => {
			const unsubscribe = onAuthStateChanged(auth, (user: any) => {
				setUser(user);
			});

			return unsubscribe;
		}, [auth]);

		if (user) {
			return <Navigate to="/dashboard" />;
		}

		return element;
	};

	// Y luego lo usas en tus rutas:
	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route
						path="/login"
						element={<LoginRoute element={<LoginScreen />} />}
					/>
					<Route path="/denuncias" element={<Home />} />
					<Route path="/contacto" element={<Home />} />
					<Route path="/about" element={<Home />} />
					<Route
						path="/dashboard"
						element={<ProtectedRoute element={<Dashboard />} />}
					/>
					<Route
						path="/reporta-la-basura-de-tu-barrio"
						element={<EmbedSurvey />}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
