import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material";
import { Column, CustomTable } from "../generic/CustomTable";
import useServices from "../../commons/hooks/useServices";
import { useEffect, useState } from "react";
import { IContact } from "../../types/CommonTypes";
import Title from "../generic/Title";
import ContactView from "./generic/ContactView";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function DashboardViewContacts() {
	const columns: Column[] = [
		{ field: "date", headerName: "Fecha", width: 150 },
		{ field: "name", headerName: "Nombre", width: 150 },
		{ field: "phoneNumber", headerName: "Teléfono", width: 150 },
		{ field: "city", headerName: "Ciudad", width: 100 },
		{ field: "message", headerName: "Mensaje", width: 250 },
		{ field: "action", headerName: "Acciones", width: 150 },
		{ field: "checked", headerName: "Revisada", width: 90 },
	];

	const { contacts, getContacts, updateRow } = useServices();
	const [selectedRow, setSelectedRow] = useState<IContact>();

	useEffect(() => {
		getContacts();
	}, []);

	const handleView = (item: any) => {
		setSelectedRow(item);
	};

	const setChecked = async (id: string, checked: boolean) => {
		updateRow(id, "reports", checked);
	};

	const reportsWithActions = contacts.map((item: any) => ({
		...item,
		checked: (
			<FormControlLabel
				control={
					<Checkbox
						checked={item.checked}
						onChange={(e) => setChecked(item.id, e.target.checked)}
						color="primary"
					/>
				}
				label=""
			/>
		),
		action: (
			<IconButton
				onClick={() => handleView(item)}
				color="primary"
				aria-label="visualizar"
			>
				<VisibilityIcon />
			</IconButton>
		),
	}));

	return (
		<Grid container>
			<Title>Tabla de contactos</Title>
			<ContactView
				isOpen={selectedRow !== undefined}
				onClose={() => setSelectedRow(undefined)}
				contact={selectedRow}
			/>
			<CustomTable
				columns={columns}
				rows={reportsWithActions}
				headerName="Mensaje"
			/>
		</Grid>
	);
}
