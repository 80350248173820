import { TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";

interface ICustomFieldProps {
	value: string;
	onChange: any;
}

export default function CustomField(props: ICustomFieldProps & TextFieldProps) {
	const { value, onChange, ...rest } = props;
	const [focused, setFocused] = useState(false);

	const handleFocus = () => {
		setFocused(true);
	};

	const handleBlur = () => {
		setFocused(false);
	};

	return (
		<TextField
			fullWidth
			value={value}
			InputProps={{
				style: {
					backgroundColor: "#f1f1f1",
					borderRadius: "20px",
				},
				disableUnderline: true,
			}}
			SelectProps={{
				style: {
					borderStyle: "none",
					borderWidth: 0,
				},
			}}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onChange={onChange}
			variant="filled"
			margin="normal"
			required
			{...rest}
		/>
	);
}
