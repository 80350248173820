import { Grid, TextField } from "@mui/material";
import Title from "../generic/Title";
import { ITestimonial } from "../../types/CommonTypes";
import Item from "./generic/ImageItem";
import { useEffect, useState } from "react";
import useFirebase from "../../commons/firebase/useFirebase";
import { getRandomId } from "../../commons/constants/helpers";

interface IProps {
	item: ITestimonial;
	onChange: any;
}

export default function DashboardTestimonials({ item, onChange }: IProps) {
	const [data, setData] = useState<ITestimonial>(item);
	const { uploadFileToStorage } = useFirebase();

	useEffect(() => {
		onChange && onChange(data);
	}, [data]);

	const handleNewPic = async (id: string, file: any) => {
		try {
			let path = "/assets/testimonials/";
			const downloadURL = await uploadFileToStorage(
				file,
				path + file.name
			);
			let updated: any = data.images.map((item) => {
				if (item.id === id) {
					return {
						id: id,
						name: getRandomId(),
						path: path,
						url: downloadURL,
					};
				}
				return item;
			});

			setData({ ...data, images: updated });
		} catch (error) {
			console.log("Error al cargar el archivo:", error);
		}
	};

	const handleText = (id: string, value: string, field: string) => {
		let updated: any = data.videos.map((video) => {
			if (video.id === id) {
				return {
					...video,
					[field]: value,
				};
			}
			return video;
		});

		setData({ ...data, videos: updated });
	};

	return (
		<Grid container>
			<Title>Imágenes</Title>
			{data.images.map((image) => (
				<Item
					label={image.name}
					source={image.url}
					onUpdate={(file: any) => handleNewPic(image.id, file)}
				/>
			))}
			<Title>Videos</Title>
			{data.videos.map((video) => (
				<Grid item md={5} style={{ margin: "20px" }}>
					<Title>{video.id}</Title>
					<TextField
						fullWidth
						value={video.title}
						label={"Título video"}
						onChange={(e) =>
							handleText(video.id, e.target.value, "title")
						}
						style={{ marginBottom: "10px" }}
					/>
					<TextField
						fullWidth
						value={video.url}
						label={"URL"}
						onChange={(e) =>
							handleText(video.id, e.target.value, "url")
						}
					/>
				</Grid>
			))}
		</Grid>
	);
}
