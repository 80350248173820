import { createTheme } from "@mui/material";
import { primary, secondary } from "./colors";

const theme = createTheme({
	palette: {
		primary: {
			main: secondary, // Tu color primario personalizado
		},
		secondary: {
			main: primary, // Tu color secundario personalizado
		},
	},
	typography: {
		fontFamily: "Source Sans Variable",
	},
});

export { theme };
