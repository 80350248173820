import { Grid, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { primary, third } from "../../commons/constants/colors";
import { isMobile } from "react-device-detect";
import { getImageById, getTextById } from "../../commons/constants/helpers";
import { IProps } from "../../types/CommonTypes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const Banner = ({ item }: IProps) => {
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div
			style={{
				width: "100vw",
				height: isMobile ? "auto" : "100vh",
				backgroundImage: `url("${getImageById("banner", item)}")`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				overflow:"hidden"
			}}
		>
			<Grid container style={{ height: isMobile ? "70%" : "100%" }}>
				<Grid
					item
					xs={8}
					md={6}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: "40px",
					}}
				>
					<Fade left delay={700}>
						<div style={{ width: "100%" }}>
							<img
								src={getImageById("name2", item)}
								alt="Small"
								style={{
									width: "80%",
									height: "auto",
									margin: 0,
									padding: 0,
								}}
							/>
						</div>
					</Fade>
					<Fade left delay={700}>
						<div style={{ width: "100%" }}>
							<Typography
								variant="h2"
								style={{
									fontSize: isMobile ? "6vw" : "4vw",
									margin: "20px 0",
									fontWeight: "bold",
									color: "white",
									textTransform: "uppercase",
									textAlign: "left",
								}}
							>
								{/* Daniel briceño */}
								{getTextById("title", item)}
							</Typography>
						</div>
						<div style={{ width: "100%" }}>
							<Typography
								variant="body1"
								style={{
									fontSize: isMobile ? "3.5vw" : "1.5vw",
									textAlign: "left",
								}}
								color={"white"}
							>
								{getTextById("subtitle", item)}
							</Typography>
						</div>
						<button
							onClick={(event) => {
								event.preventDefault();
								const element =
									document.querySelector("#about");
								if (element) {
									element.scrollIntoView({
										behavior: "smooth",
									});
								} else {
									console.warn(
										'No se encontró el elemento con el ID "about".'
									);
								}
							}}
							style={{
								padding: "1.5vw",
								width: isMobile ? "20vw" : "15vw",
								borderRadius: "50px",
								backgroundColor: third,
								alignSelf: "flex-start",
								marginTop: isMobile ? "10px" : "50px",
								textDecoration: "none",
								border: "none",
							}}
						>
							<Typography
								variant="h1"
								color={primary}
								fontWeight={800}
								style={{
									fontSize: isMobile ? "4vw" : "2vw",
								}}
								textAlign={"center"}
							>
								Quién soy
							</Typography>
						</button>
					</Fade>
				</Grid>
				<Grid
					item
					xs={4}
					md={6}
					display={{ xs: "flex", md: "flex" }}
					style={{
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Zoom ssrFadeout delay={500}>
						<img
							src={getImageById("profile", item)}
							alt="Large"
							style={{
								width: isMobile ? "140%" : "100%",
								alignSelf: isMobile ? "flex-end" : "flex-end",
								marginBottom: isMobile ? "30px" : "0px",
								marginRight: isMobile ? "55px" : "0px",
							}}
						/>
					</Zoom>
				</Grid>
			</Grid>
			{/* </Fade> */}

			<IconButton
				edge="start"
				color="inherit"
				aria-label="menu"
				onClick={handleClick}
				style={{
					position: "absolute",
					right: 50,
					top: 50,
					color: "white",
				}}
			>
				<MenuIcon style={{ fontSize: "1.5em" }} />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => navigate("/login")}>
					{getAuth().currentUser
						? "Ir al dashboard"
						: "Iniciar sesión"}
				</MenuItem>
			</Menu>
		</div>
	);
};

export default Banner;
