import { useEffect, useState } from "react";
import { IWebsite } from "../types/CommonTypes";
import {
	getFirestore,
	collection,
	getDocs,
	doc,
	updateDoc,
	setDoc,
	getDoc,
} from "firebase/firestore";

export default function useWebsite() {
	const [website, setWebsite] = useState<IWebsite>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		let isMounted = true;
		isMounted && getWebSiteInfo();
		return () => {
			isMounted = false;
		};
	}, []);

	const updateWebsite = async (website: any) => {
		const db = getFirestore();
		setLoading(true);

		try {
			const websiteCollectionRef = collection(db, "website");

			// Recorre los documentos en el objeto websiteData
			for (const docId in website) {
				if (Object.hasOwnProperty.call(website, docId)) {
					const docData = website[docId];
					const docRef = doc(websiteCollectionRef, docId);

					// Verifica si el documento existe y actualízalo o créalo
					const docSnapshot = await getDoc(docRef);
					if (docSnapshot.exists()) {
						await updateDoc(docRef, docData);
					} else {
						await setDoc(docRef, docData);
					}
				}
			}

			alert("Actualización exitosa");
			setLoading(false);
			console.log('La colección "website" se actualizó correctamente.');
		} catch (error) {
			alert("Ha ocurrido un error");
			console.error('Error al actualizar la colección "website":', error);
			setLoading(false);
		}
	};

	const getWebSiteInfo = async () => {
		const db = getFirestore();
		const websiteCollectionRef = collection(db, "website");
		const websiteSnapshot = await getDocs(websiteCollectionRef);

		let info: any = {};
		websiteSnapshot.docs.map((doc: any) => (info[doc.id] = doc.data()));

		setWebsite(info);
	};

	return {
		website,
		loading,
		setWebsite,
		updateWebsite,
	};
}
