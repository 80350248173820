import { Grid } from "@mui/material";
import { IProps } from "../../types/CommonTypes";
import Title from "../generic/Title";
import Item from "./generic/ImageItem";
import { useEffect, useState } from "react";
import useFirebase from "../../commons/firebase/useFirebase";
import TextItem from "./generic/TextItem";
import { getRandomId } from "../../commons/constants/helpers";

export default function DashboardAbout({ item, onChange }: IProps) {
	const [data, setData] = useState(item);
	const { uploadFileToStorage } = useFirebase();

	useEffect(() => {
		onChange && onChange(data);
	}, [data]);

	const handleNewPic = async (id: string, file: any) => {
		try {
			let path = "/assets/about/";
			const downloadURL = await uploadFileToStorage(
				file,
				path + file.name
			);
			let updated: any = data.images.map((item) => {
				if (item.id === id) {
					return {
						id: id,
						name: getRandomId(),
						path: path,
						url: downloadURL,
					};
				}
				return item;
			});

			setData({ ...data, images: updated });
		} catch (error) {
			console.log("Error al cargar el archivo:", error);
		}
	};

	const handleTextChange = (id: string, value: string) => {
		let updated: any = data.texts.map((item) => {
			if (item.id === id) {
				return {
					...item,
					text: value,
				};
			}
			return item;
		});

		setData({ ...data, texts: updated });
	};

	return (
		<Grid container>
			<Title>Imágenes</Title>

			{data.images.map((image) => (
				<Item
					label={image.name}
					source={image.url}
					onUpdate={(file: any) => handleNewPic(image.id, file)}
				/>
			))}
			<Title>Textos</Title>
			{data.texts.map((item) => (
				<TextItem
					id={item.id}
					text={item.text}
					handleChange={(value: string) =>
						handleTextChange(item.id, value)
					}
				/>
			))}
		</Grid>
	);
}
