import React from "react";
import { Grid, Tooltip, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import * as XLSX from "xlsx";

export interface Column {
	field: string;
	headerName: string;
	width?: number;
	type?: "string" | "number" | "date" | "dateTime";
}

interface TableProps {
	columns: Column[];
	rows: GridRowsProp;
	pageSize?: number;
	headerName: string;
}

export const CustomTable = ({
	columns,
	rows,
	pageSize = 10,
	headerName,
}: TableProps) => {
	const columnsWithRenderCell: GridColDef[] = columns.map((column) => {
		if (column.headerName === headerName) {
			return {
				...column,
				renderCell: (params) => (
					<Tooltip
						title={params.value as string}
						placement="top-start"
					>
						<span>{params.value as string}</span>
					</Tooltip>
				),
			};
		} else {
			return {
				...column,
				renderCell: (params) => (
					<>
						<span>{params.value}</span>
					</>
				),
			};
		}
	});

	const handleDownload = () => {
		const ws = XLSX.utils.json_to_sheet(
			rows.map((row) => {
				let newRow: any = {};
				columns.forEach((col) => {
					newRow[col.headerName] = row[col.field];
				});
				return newRow;
			})
		);

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		// Escribe el archivo y provoca su descarga
		XLSX.writeFile(wb, "datos.xlsx");
	};

	return (
		<Grid
			container
			style={{
				height: 600,
			}}
		>
			{/* <Button
				onClick={handleDownload}
				variant="contained"
				color="primary"
				style={{ marginBottom: "10px" }}
			>
				Descargar Excel
			</Button> */}
			<DataGrid rows={rows} columns={columnsWithRenderCell} pagination />
		</Grid>
	);
};
