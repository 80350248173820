import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import { ITestimonial } from "../../types/CommonTypes";
import { getImageById } from "../../commons/constants/helpers";
import Fade from "react-reveal/Fade";
import VideoItem from "./VideoItem";

interface IProps {
	item: ITestimonial;
}
const VideoSection = ({ item }: IProps) => {
	const { videos, images } = item;
	const videoColumns = videos;

	return (
		<Grid
			container
			style={{
				display: "flex",
				backgroundImage: `url("${getImageById("banner", {
					images: images,
					texts: [],
				})}")`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				padding: "10px",
				paddingTop: "50px",
				paddingBottom: "50px",
				justifyContent: "center",
				width: "100%",
				height: "auto",
			}}
		>
			<Fade left delay={500}>
				<Grid
					item
					style={{
						display: "flex",
						width: "100%",
						flexDirection: isMobile ? "column" : "row",
					}}
				>
					{videoColumns.map((column, index) => (
						<VideoItem
							md={4}
							key={index}
							url={column.url}
							title={column.title}
						/>
					))}
				</Grid>
			</Fade>
		</Grid>
	);
};

export default VideoSection;
