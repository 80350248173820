import { useEffect, useRef, useState } from "react";
import "./styles/Survey.css";
import Footer from "../components/footer/Footer";
import useWebsite from "../services/useWebsite";
import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { isDesktop } from "react-device-detect";

const EmbedSurvey = () => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const { website } = useWebsite();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const handleResize = (e: MessageEvent) => {
			if (e.data) {
				try {
					const message = JSON.parse(e.data);
					if (
						[
							"survey123:webform:formLoaded",
							"survey123:onFormLoaded",
						].includes(message.event) &&
						message.contentHeight
					) {
						if (iframeRef.current) {
							iframeRef.current.style.height = `${message.contentHeight}px`;
						}
					}
				} catch (error) {
					console.error("Error parsing message from iframe:", error);
				}
			}
		};

		window.addEventListener("message", handleResize);

		return () => {
			window.removeEventListener("message", handleResize);
		};
	}, []);

	const handleIframeLoad = () => {
		setLoading(false);
	};

	if (!website) return null;

	return (
		<div className="embed-container">
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Stack width={"100%"} alignItems={"center"} flexBasis={"100%"}>
				<iframe
					ref={iframeRef}
					name="survey123webform"
					title="Puntos Criticos de Basura"
					src="//survey123.arcgis.com/share/ff25c547e1404d668bd879cf326363d8"
					style={{
						width: isDesktop ? "100%" : "400px",
						border: "none",
						height: isDesktop ? "2700px" : "2600px",
						marginBlock: isDesktop ? "50px" : 0,
					}}
					allow="geolocation https://survey123.arcgis.com; camera https://survey123.arcgis.com"
					onLoad={handleIframeLoad}
				/>
				<Footer item={website?.footer!} />
			</Stack>
		</div>
	);
};

export default EmbedSurvey;
