import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DashboardBanner from "./DashboardBanner";
import { useState } from "react";
import useWebsite from "../../services/useWebsite";
import { Button, CircularProgress, Grid } from "@mui/material";
import DashboardAbout from "./DashboardAbout";
import DashboardTestimonials from "./DashboardTestimonials";
import DashboardReports from "./DashboardReports";
import DashboardForm from "./DashboardForm";
import DashboardContact from "./DashboardContact";
import DashboardFooter from "./DashboardFooter";
import { useNavigate } from "react-router-dom";
import useAuth from "../../services/useAuth";
import StorageIcon from "@mui/icons-material/Storage";
import DashboardViewReports from "./DasboardViewReports";
import DashboardViewContacts from "./DashboardViewContacts";

const drawerWidth = 240;

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = useState(false);
	const [currentOption, setCurrentOption] = useState("Banner");
	const { website, loading, setWebsite, updateWebsite } = useWebsite();
	const { signOut } = useAuth();
	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const menu = [
		{ label: "Banner" },
		{ label: "Acerca de" },
		{ label: "Testimonios" },
		{ label: "Denuncias" },
		{ label: "Denuncia aquí" },
		{ label: "Contacto" },
		{ label: "Pie de página" },
	];

	const databaseMenu = [
		{ label: "Denuncias por usuarios" },
		{ label: "Contactos" },
	];

	const options = [
		{
			label: "Volver al inicio",
			icon: <ArrowBackIcon />,
			action: () => navigate("/"),
		},
		{
			label: "Cerrar Sesión",
			icon: <LogoutIcon />,
			action: async () => {
				await signOut();
				navigate("/");
			},
		},
	];

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				{menu.map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						onClick={() => {
							setCurrentOption(item.label);
						}}
					>
						<ListItemButton>
							<ListItemIcon>
								<LabelImportantIcon />
							</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />

			<List>
				{databaseMenu.map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						onClick={() => {
							setCurrentOption(item.label);
						}}
					>
						<ListItemButton>
							<ListItemIcon>
								<StorageIcon />
							</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />
			<List>
				{options.map((item, index) => (
					<ListItem key={index} disablePadding onClick={item.action}>
						<ListItemButton>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	const handleGlobalChanges = (id: string, body: any) => {
		if (website) setWebsite({ ...website, [id]: body });
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			<AppBar
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}
					>
						<MenuIcon />
					</IconButton>

					<Typography variant="h6" noWrap component="div">
						Perfil de administrador
					</Typography>
					<Box sx={{ flexGrow: 1 }} />

					{loading ? (
						<Box sx={{ display: "flex" }}>
							<CircularProgress
								color="inherit"
								style={{ color: "white" }}
							/>
						</Box>
					) : (
						<Button
							style={{ backgroundColor: "white" }}
							color="inherit"
							onClick={() => updateWebsite(website)}
						>
							Guardar
						</Button>
					)}
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			{website ? (
				<Box
					component="main"
					sx={{
						marginTop: "20px",
						display: "flex",
						flexGrow: 1,
						p: 3,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						justifyContent: "center",
					}}
				>
					<Toolbar />
					{currentOption === "Banner" && (
						<DashboardBanner
							item={website.banner}
							onChange={(banner: any) =>
								handleGlobalChanges("banner", banner)
							}
						/>
					)}
					{currentOption === "Acerca de" && (
						<DashboardAbout
							item={website.about}
							onChange={(banner: any) =>
								handleGlobalChanges("about", banner)
							}
						/>
					)}
					{currentOption === "Testimonios" && (
						<DashboardTestimonials
							item={website.testimonials}
							onChange={(banner: any) =>
								handleGlobalChanges("testimonials", banner)
							}
						/>
					)}
					{currentOption === "Denuncias" && (
						<DashboardReports
							item={website.reports}
							onChange={(banner: any) =>
								handleGlobalChanges("reports", banner)
							}
						/>
					)}
					{currentOption === "Denuncia aquí" && (
						<DashboardForm
							item={website.reportForm}
							onChange={(banner: any) =>
								handleGlobalChanges("reportForm", banner)
							}
						/>
					)}
					{currentOption === "Contacto" && (
						<DashboardContact
							item={website.contact}
							onChange={(banner: any) =>
								handleGlobalChanges("contact", banner)
							}
						/>
					)}
					{currentOption === "Pie de página" && (
						<DashboardFooter
							item={website.footer}
							onChange={(banner: any) =>
								handleGlobalChanges("footer", banner)
							}
						/>
					)}

					{currentOption === "Denuncias por usuarios" && (
						<DashboardViewReports />
					)}

					{currentOption === "Contactos" && <DashboardViewContacts />}
				</Box>
			) : (
				<Grid
					container
					sx={{
						marginTop: "20px",
						display: "flex",
						height: "100vh",
						width: `90vw`,
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<CircularProgress
						color="primary"
						style={{ alignSelf: "center" }}
					/>
				</Grid>
			)}
		</Box>
	);
}
