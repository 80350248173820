import { useState, ChangeEvent, FormEvent } from "react";
import {
	Button,
	Box,
	Grid,
	Typography,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	IconButton,
} from "@mui/material";
import CustomField from "./CustomField";
import { isMobile } from "react-device-detect";
import { darkBlue } from "../../commons/constants/colors";
import { IProps } from "../../types/CommonTypes";
import { formatDate, getImageById } from "../../commons/constants/helpers";
import useServices from "../../commons/hooks/useServices";
import Jump from "react-reveal/Jump";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { toast } from "react-toastify";

interface FormValues {
	name: string;
	phoneNumber: string;
	report: string;
	date: string;
}

const initialState = {
	name: "",
	phoneNumber: "",
	report: "",
	date: "",
};

const Form = ({ item }: IProps) => {
	const [formValues, setFormValues] = useState<FormValues>(initialState);
	const { handelSubmitReport, loading } = useServices();
	const [checked, setChecked] = useState<boolean>(false);
	const [file, setFile] = useState<any>(null);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === "phoneNumber") {
			setFormValues((prevValues) => ({
				...prevValues,
				[name]: value.replace(/[^0-9]/g, ""),
			}));
		} else {
			setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
		}
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		// Aquí puedes realizar las acciones necesarias con los valores del formulario
		await handelSubmitReport({
			...formValues,
			date: formatDate(new Date().toString()),
			file: file,
		});
		setFile(null);
		setChecked(false);
		setFormValues(initialState);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const selectedFile = e.target.files[0];

			// Verifica si el tamaño del archivo es mayor que 1GB
			if (selectedFile.size > 100 * 1024 * 1024) {
				// 100MB en bytes
				toast.error(
					"El archivo seleccionado es demasiado grande. Por favor, elige un archivo que pese menos de 100MB"
				);
				return;
			}

			setFile(selectedFile);
		}
	};

	if (!isMobile)
		return (
			<Grid
				id="denuncias"
				container
				style={{
					marginTop: "100px",
					backgroundImage:
						"linear-gradient(to bottom, #d3dfed 50%, white 50%)",
					paddingTop: "20px",
				}}
			>
				<Grid item md={6} xs={12}>
					<Typography
						variant="h1"
						fontFamily="GlitterButter"
						color={darkBlue}
						fontSize={"19vw"}
						sx={{
							position: "absolute",
							transform: "rotate(-5deg)",
							marginTop: "210px",
							marginLeft: "20px",
						}}
						textAlign="center"
					>
						Denuncias
					</Typography>
					<Box
						maxWidth="400px"
						margin="0 auto"
						padding={2}
						style={{
							padding: "20px",
							borderRadius: "30px",
							marginRight: "-30px",
							backgroundColor: "white",
							position: "relative",
							zIndex: 1,
						}}
					>
						<form onSubmit={handleSubmit}>
							<CustomField
								fullWidth
								label="Nombre"
								name="name"
								value={formValues.name}
								onChange={handleChange}
								inputProps={{ maxLength: 100 }}
							/>
							<CustomField
								fullWidth
								label="Número de celular"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={handleChange}
								inputProps={{ maxLength: 11 }}
							/>
							<CustomField
								fullWidth
								label="Denuncia"
								name="report"
								value={formValues.report}
								onChange={handleChange}
								multiline
								rows={10}
								inputProps={{ maxLength: 3000 }}
							/>
							<input
								accept=".zip"
								id="contained-button-file"
								type="file"
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>
							{!file ? (
								<label htmlFor="contained-button-file">
									<Button
										fullWidth
										variant="outlined"
										component="span"
										sx={{ my: 1 }}
										disabled={loading}
									>
										Cargar Archivo (.zip)
									</Button>
								</label>
							) : (
								<Box
									sx={{
										my: 1,
										display: "flex",
										alignItems: "center",
									}}
								>
									<p>{file.name}</p>
									<IconButton onClick={() => setFile(null)}>
										<RemoveCircleIcon color="error" />
									</IconButton>
								</Box>
							)}
							<FormControlLabel
								control={
									<Checkbox
										checked={checked}
										onChange={(e) =>
											setChecked(e.target.checked)
										}
										color="primary"
									/>
								}
								label="Acepto el uso y tratamiento de datos personales"
							/>
							{!loading ? (
								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									style={{
										borderRadius: "20px",
										display: checked ? "flex" : "none",
									}}
								>
									Enviar
								</Button>
							) : (
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</form>
					</Box>
				</Grid>
				<Jump delay={2000}>
					<Grid
						item
						md={6}
						xs={12}
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							padding: "10px",
						}}
					>
						<img
							src={getImageById("title", item)}
							alt="denuncia"
							style={{
								width: "400px",
								height: "200px",
								marginLeft: "20px",
								marginTop: "-100px",
							}}
						/>
						<img
							src={getImageById("megaphone", item)}
							alt="megaphone"
							style={{
								width: "450px",
								height: "450px",
								marginLeft: "-40px",
								marginTop: "-100px",
								zIndex: 10,
								transform: "scaleX(-1)",
							}}
						/>
					</Grid>
				</Jump>
			</Grid>
		);
	else
		return (
			<Grid
				container
				style={{
					marginTop: "10px",
					backgroundImage:
						"linear-gradient(to bottom, #d3dfed 80%, white 20%)",
					paddingTop: "20px",
				}}
			>
				<Grid
					item
					md={6}
					xs={12}
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						padding: "10px",
					}}
				>
					<img
						src={getImageById("megaphone", item)}
						alt="megaphone"
						style={{
							width: "150px",
							height: "150px",
							marginTop: "30px",
							marginLeft: "-10px",
							zIndex: 1,
							transform: "rotate(-25deg)",
						}}
					/>
					<img
						src={getImageById("title", item)}
						alt="title"
						style={{
							width: "300px",
							height: "150px",
							marginLeft: "-30px",
							zIndex: 0,
						}}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Box
						margin="0 auto"
						padding={2}
						style={{
							padding: "20px",
							// borderRadius: "30px",
							backgroundColor: "white",
						}}
					>
						<form onSubmit={handleSubmit}>
							<CustomField
								fullWidth
								label="Nombre"
								name="name"
								value={formValues.name}
								onChange={handleChange}
								inputProps={{ maxLength: 100 }}
							/>
							<CustomField
								fullWidth
								label="Número de celular"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={handleChange}
								inputProps={{ maxLength: 11 }}
							/>
							<CustomField
								fullWidth
								label="Denuncia"
								name="report"
								value={formValues.report}
								onChange={handleChange}
								multiline
								rows={10}
								inputProps={{ maxLength: 3000 }}
							/>
							<input
								accept=".zip"
								id="contained-button-file"
								type="file"
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>
							{!file ? (
								<label htmlFor="contained-button-file">
									<Button
										fullWidth
										variant="outlined"
										component="span"
										sx={{ my: 1 }}
										disabled={loading}
									>
										Cargar Archivo (.zip)
									</Button>
								</label>
							) : (
								<Box
									sx={{
										my: 1,
										display: "flex",
										alignItems: "center",
									}}
								>
									<p>{file.name}</p>
									<IconButton onClick={() => setFile(null)}>
										<RemoveCircleIcon color="error" />
									</IconButton>
								</Box>
							)}
							<FormControlLabel
								control={
									<Checkbox
										checked={checked}
										onChange={(e) =>
											setChecked(e.target.checked)
										}
										color="primary"
									/>
								}
								label="Acepto el uso y tratamiento de datos personales"
							/>

							{!loading ? (
								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									style={{
										borderRadius: "20px",
										display: checked ? "flex" : "none",
									}}
								>
									Enviar
								</Button>
							) : (
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</form>
					</Box>
				</Grid>
			</Grid>
		);
};

export default Form;
