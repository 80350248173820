import {
	collection,
	doc,
	getDocs,
	getFirestore,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { useState } from "react";
import { IContact, IReport } from "../../types/CommonTypes";
import { toast } from "react-toastify";
import useStorage from "./useStorage";

export default function useServices() {
	const [loading, setLoading] = useState<boolean>(false);
	const [reports, setReports] = useState([]);
	const [contacts, setContacts] = useState([]);
	const { uploadFile } = useStorage();

	const getReports = async () => {
		const db = getFirestore();
		const websiteCollectionRef = collection(db, "reports");
		const websiteSnapshot = await getDocs(websiteCollectionRef);
		let mapper: any = [];
		websiteSnapshot.docs.map((doc: any) => {
			let data = doc.data();

			return mapper.push({
				id: doc.id,
				...data,
			});
		});
		setReports(mapper);
	};

	const getContacts = async () => {
		const db = getFirestore();
		const websiteCollectionRef = collection(db, "contacts");
		const websiteSnapshot = await getDocs(websiteCollectionRef);

		let mapper: any = [];
		websiteSnapshot.docs.map((doc: any) => {
			let data = doc.data();
			return mapper.push({
				id: doc.id,
				...data,
			});
		});
		setContacts(mapper);
	};

	const handelSubmitReport = async (report: IReport) => {
		setLoading(true);
		try {
			const db = getFirestore();
			const newId = doc(collection(db, "reports")).id;

			let fileData = null;
			//Cargamos el archivo
			if (report.file) {
				fileData = await uploadFile(report.file, newId, report.name);
			}

			//Creamos el documento
			const docRef = await setDoc(doc(db, "reports", newId), {
				...report,
				file: fileData,
			});
			console.log("Documento creado con ID:", docRef);
			toast.success("Hemos registrado tu denuncia");
			return newId;
		} catch (error) {
			console.error("Error al crear el documento:", error);
			toast.error("Ha ocurrido un error, intenta nuevamente");
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handleCreateContact = async (newContact: IContact) => {
		setLoading(true);
		try {
			const db = getFirestore();
			const newId = doc(collection(db, "contacts")).id;
			newContact.id = newId;
			newContact.checked = false;
			const docRef = await setDoc(doc(db, "contacts", newId), newContact);
			console.log("Documento creado con ID:", docRef);
			toast.success("Hemos registrado tus datos");
			return newId;
		} catch (error) {
			console.error("Error al crear el documento:", error);
			toast.error("Ha ocurrido un error, intenta nuevamente");
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const updateRow = async (
		id: string,
		collection: string,
		checked: boolean
	) => {
		try {
			const db = getFirestore();
			const docRef = doc(db, collection, id); // obtener referencia al documento
			await updateDoc(docRef, { checked: checked }); // actualizar campo "checked"
		} catch (error) {
			console.log("Error al actualizar el documento:", error);
			toast.error("Ha ocurrido un error, intenta nuevamente");
			throw error;
		}
	};

	return {
		loading,
		reports,
		contacts,
		handelSubmitReport,
		handleCreateContact,
		getContacts,
		getReports,
		updateRow,
	};
}
