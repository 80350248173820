import { useState, ChangeEvent, FormEvent } from "react";
import {
	Container,
	Typography,
	TextField,
	Button,
	Box,
	CircularProgress,
} from "@mui/material";
import useAuth from "../services/useAuth";
import { useNavigate } from "react-router-dom";

interface LoginForm {
	email: string;
	password: string;
}

const LoginScreen = () => {
	const { signIn, error, loading } = useAuth();
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState<LoginForm>({
		email: "",
		password: "",
	});

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		await signIn(formValues.email, formValues.password);
	};

	return (
		<div
			style={{
				backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/danielbricen-cc9d9.appspot.com/o/assets%2Fbanner%2Fbanner.png?alt=media&token=9950b1e7-8a99-4c80-adda-a9de8b825894")`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				minHeight: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography
					variant="h4"
					component="h1"
					color="white"
					style={{ marginBottom: "2rem" }}
				>
					¡Bienvenido!
				</Typography>
				{!loading ? (
					<form
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "1rem",
							width: "300px",
							backgroundColor: "white",
							padding: "50px",
							borderRadius: "20px",
						}}
						onSubmit={handleSubmit}
					>
						<TextField
							type="email"
							label="Correo electrónico"
							variant="outlined"
							name="email"
							value={formValues.email}
							onChange={handleInputChange}
							required
						/>
						<TextField
							type="password"
							label="Contraseña"
							variant="outlined"
							name="password"
							value={formValues.password}
							onChange={handleInputChange}
							required
						/>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							style={{ marginTop: "1rem" }}
						>
							Iniciar sesión
						</Button>
						<Button onClick={() => navigate("/")} color="inherit">
							Volver al inicio
						</Button>
						{error && (
							<Typography
								align="center"
								variant="body1"
								color="error"
							>
								{error.message}
							</Typography>
						)}
					</form>
				) : (
					<Box sx={{ display: "flex" }}>
						<CircularProgress />
					</Box>
				)}
			</Container>
		</div>
	);
};

export default LoginScreen;
